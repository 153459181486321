import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export default function FooterLinks() {
  return (
    <Box
      sx={{
        typography: 'body1',
        '& > :not(style) ~ :not(style)': {
          ml: 2,
        },
      }}
      onClick={preventDefault}
    >
      <Link underline="none" href="#">Home</Link>
      <Link underline="none" href="#">Contact Us</Link>
      <Link underline="none" href="#">Security</Link>
      <Link underline="none" href="#">IPR Compliants</Link>
      <Link underline="none" href="#">ANti-spam Plicy</Link>      
      <Link underline="none" href="#">Privacy Policy</Link>
      <br/>
      <Link underline="none" href="#">Terms of Service</Link>
      <Link underline="none" href="#">Cookie Policy</Link>
      <Link underline="none" href="#">GDPR Compliance</Link>
      <Link underline="none" href="#">Abuse Policy</Link>
      <br/>
    </Box>
  );
}