import * as React from "react";
import Box from "@mui/material/Box";
import widepanorma from "./assets/images/widepanorma.jpg";

export default function FullWidthBanner() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "10vh",
        width: "100%",
        py: 0,
        mt: "auto",
      }}
    >
        <img
          class="widepanormaimage"
          src={widepanorma}
          width="100%"
          height="300px"
          alt=""
        />
      </Box>
  );
}
