import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import nasicon from "./assets/images/nasicon.png";
import cloudicon from "./assets/images/cloudicon.png";
import pyramidicon from "./assets/images/pyramidicon.png";


import techlifeicon from "./assets/images/techlifeicon.png";
import plasmidicon from "./assets/images/plasmidicon.png";
import deviceicon from "./assets/images/deviceicon.png";

import infoicon from "./assets/images/infoicon.png";
import systemicon from "./assets/images/systemicon.png";


import world from "./assets/images/world.png";
import slicesContent from "./slicescontent.js";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

function getPic(txtname) {
  if(txtname === "nasicon") return nasicon;
  if(txtname === "cloudicon") return cloudicon;
  if(txtname === "pyramidicon") return pyramidicon;
  if(txtname === "techlifeicon") return techlifeicon;
  if(txtname === "plasmidicon") return plasmidicon;
  if(txtname === "deviceicon") return deviceicon;
  if(txtname === "infoicon") return infoicon;
  if(txtname === "systemicon") return systemicon;

  return "https://source.unsplash.com/random?wallpapers";
}

function LocalCard(props) {
  return (
    <Card
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
      <CardMedia
        component="div"
        sx={{
          // 16:9
          pt: "12%",
          bgcolor: "#29a0b1",
          height: "100px"
        }}>
        <img src={getPic(props.pic)} width="20%" alt="" align="left"/>
        <Typography gutterBottom variant="h5" component="h2" color="white">
          {(props.heading ) ? props.heading  : "Heading"}
        </Typography>
        </CardMedia>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography sx={{justifyContent: "left"}}>
        {(props.content ) ? props.content  : "Content"}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
        {/* <Button size="small">Submit Request</Button>  */}
      </CardActions>
    </Card>
  );
}

/////////////////////////////////////////////////////////////



export default function NewWiIP() {
  const slicesdata = slicesContent()[1];
  return (
    <div style={{ backgroundImage: `url(${world})`, backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
    <Container align= "center" sx={{ py: 8, width: "100%" }} maxWidth="lg">
      {/* End hero unit */}
      <Grid container spacing={2}>
        {slicesdata.map((card) => (
          <Grid item key={card.id} xs={12} sm={4} md={4} lg={3}>
            <LocalCard key={card.id} pic={card.pic}  heading={card.heading} content={card.content}>
            </LocalCard>
          </Grid>
        ))}
      </Grid>
    </Container>
    </div>
  );
}
