import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

export default function SignUpSlice () {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "15vh",
        mt: "auto",
        width: "100%",
        align: "center",
        backgroundColor: "#ADD8E6",
        color: "#167d7f"
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: "#FFFFFF",
            marginTop: "-5%"
        }}
      >
        <Typography variant="h2" align="center" sx={{marginTop: "40px"}}>
          Ready to do your best work?
        </Typography>
        <br/>
          <Typography variant="h4" align="center" sx={{ marginTop: "10px", marginBottom: "25px" }} >
            Let's get started
          </Typography>
          <br/>
          <Typography variant="h4" align="center" sx={{ marginTop: "5px", marginBottom: "40px" }} >
          <Button variant="contained" sx={{ align: "center"}} align="center" onClick={() => window.location.replace("/#contact")}>Sign Up Now</Button>
          
          <br/>
          <br/>
          <LinearProgress color="secondary" />
          </Typography>
        </Container>
    </Box>
  );
}
