import './App.css';
import Album from './Album'

function App() {
  return (
  <Album/>
  );
}

export default App;
