import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import customcardsimg1 from "./assets/images/customcardsimg1.JPG";
import customcardsimg2 from "./assets/images/customcardsimg2.JPG";
import customcardsimg3 from "./assets/images/customcardsimg3.JPG";

const cardsData = [
    {
        id:1,
        pic: "customcardsimg1",
        heading:"Long term Commitment",
        content:"As a business with several years of experience, we understand the challenges that growing businesses face. We are proud to have built a sustainable business model that relies on our customers to thrive and providing you with the tools and resources you need to grow your business."
    }, 
    {
        id:2,
        pic: "customcardsimg2",
        heading:"Focus on Innovation",
        content: "Over the years, our customers' trust and loyalty have been the cornerstone of our success in the market. Our unwavering commitment to supporting businesses of all sizes has helped us establish a strong position in the industry. We are passionate about helping you achieve your goals."
    }, 
    {
        id:3,
        pic: "customcardsimg3",
        heading: "Customer First",
        content: "Software development is a core area of expertise for us, and we take great pride in our continuous investment in research and development. We are committed to owning and managing our entire technology stack, which includes our global network of data centers."
    }
];

function getPic(txtname) {
    if(txtname === "customcardsimg1") return customcardsimg1;
    if(txtname === "customcardsimg2") return customcardsimg2;
    if(txtname === "customcardsimg3") return customcardsimg3;
    return "https://source.unsplash.com/random?wallpapers";
  }
  

function CustomCard (props) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "15vh",
                py: 3,
                px: 2,
                mt: "auto",
                width: "100%",
                align: "center",
                backgroundColor:  "white", 
                color: "#167d7f"
            }}
        >
        <img src={getPic(props.pic)} width="14%" alt="" />
        <Typography  variant="h5"
            sx={{ float: "left", marginTop: "2%", marginLeft: "1%", lineHeight: "1.3", letterSpacing: "-0.5px", width: "82%" }}
            gutterBottom>
            {props.heading}
        </Typography>
        <Typography sx={{ float: "left", lineHeight: "1.7", fontSize: "18px", letterSpacing: "-0.2px" }}>
        {props.content}
        </Typography>
        </Box>
    );
}

export default function CustomCards() {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                minHeight: "15vh",
                py: 3,
                px: 2,
                mt: "auto",
                width: "100%",
                align: "center",
                backgroundColor:  "FFBF00",
                color: "#167d7f"
            }}
        >
            {cardsData.map((card) => (
                <CustomCard key={card.id} pic={card.pic} heading={card.heading} content={card.content} >

                </CustomCard>
              ))}
        </Box>);

}
