import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Image from "mui-image";
import tuneicon from "./assets/images/tuneicon.png";

export default function CraftsSlice () {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "15vh",
        py: 3,
        px: 2,
        mt: "auto",
        width: "100%",
        align: "center",
        backgroundColor:  "#98d7c2", //"#FFBF00",
        color: "#167d7f",
        //marginBottom: "-160px"
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          color: "#167d7f"
        }}
      >
        <Typography variant="h4" align="center" >
        What we're driven by are the core values and principles
        </Typography>
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "row",            
          }}
        >
          <Image src={tuneicon} width="250px" alt="" />
          <Typography
            variant="h6"
            sx={{ float: "right", marginTop: "2%", marginLeft: "10%", marginRight: "10%" }}
            gutterBottom
          >
            We develop a workplace to be very functional and there are a lot of solutions that make sense for clinets in one single package, like email, calendar, file management, and productivity solutions. This makes the work more systematic across the whole company and we are able to collaborate more efficiently.
          </Typography>
        </Container>
      </Container>
    </Box>
  );
}
