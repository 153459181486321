import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Image from "mui-image";
import privacy from "./assets/images/privacy.png";

export default function PrivacySlice() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "15vh",
        py: 3,
        px: 2,
        mt: "auto",
        width: "100%",
        align: "center",
        backgroundColor:  "#29a0b1" //"rgb(217, 234, 228)",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#29a0b1",//  "#ADD8E6",
          //marginTop: "-20p"
        }}
      >
        <Typography variant="h6" align="center" sx={{marginTop: "10px"}}>
          
        </Typography>
        <Typography variant="h6" sx={{ align: "center" }}>

        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "row",
            align: "48%",
            // backgroundColor: "cyan",
            width: "90px",
            lineHeight: "1.5",
            color: "#FFBF00"
          }}
        >
          <Image src={privacy} position="relative" width="90px" height="auto"  align="center" alt="" background-color="red"/>  
        </Container>              
        </Typography>
        <Typography variant="h3" align="center" sx={{color: "#ddffe7", marginTop: "10px"}}>
          Responsible Partner
        </Typography>
        <Typography variant="h6" sx={{color: "#ddffe7", marginBottom: "15px"}}>
        We understand the importance of trust in any relationship, and we take it very seriously. We want to assure you that we do not own or sell your data, nor do we rely on advertising-based business models to make money. The only way we generate revenue is through the software license fees you pay us.
        </Typography>
      </Container>
    </Box>
  );
}
