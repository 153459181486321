import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import scale from "./assets/images/scale.png";
import target from "./assets/images/target.png";
import track from "./assets/images/track.png";

function getPic(txtname) {
  if(txtname === "target") return target;
  if(txtname === "scale") return scale;
  if(txtname === "track") return track;
  return "https://source.unsplash.com/random?wallpapers";
}

export default function AlbumCard(props) {
  return (
    <Card
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
      <CardMedia
        component="div"
        sx={{
          // 16:9
          pt: "12%",
          bgcolor: "#ededf0"
        }}
                /* image= {getPic(props.pic)}  */
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <img src={getPic(props.pic)} width="40%" alt="" />
        <Typography gutterBottom variant="h5" component="h2">
          {(props.heading ) ? props.heading  : "Heading"}
        </Typography>
        <Typography>
        {(props.content ) ? props.content  : "Heading"}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">View</Button>
        <Button size="small">Submit Request</Button>
      </CardActions>
    </Card>
  );
}
