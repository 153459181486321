import * as React from "react";
import Typography from "@mui/material/Typography";
import { Rating } from "@mui/material";

export default function Ratings() {
  const [value, setValue] = React.useState(5);

  return (
    <React.Fragment>
      <Typography component="legend">
        Our Excellent Client Satisifcation Track
      </Typography>
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(5);
        }}
      />
      <br/>
      <br/>
      <br/>
    </React.Fragment>
  );
}
