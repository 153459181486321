import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StickyFooter from "./StickyFooter";
import AlbumCard from "./AlbumCard";
import CraftsSlice from "./CraftsSlice";
import Ratings from "./Ratings";
import PrivacySlice from "./PrivacySlice";
import logo from "./assets/images/st.png";

import FullWidthBanner from "./FullWidthBanner";
import SignUpSlice  from "./SignUpSlice";
import CustomCards from"./CustomCards";
import slicesContent from "./slicescontent.js";
import NewWIP from "./NewWIP.js";

const dataSlices = slicesContent();

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Album() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppBar position="relative" sx={{bgcolor: "#f8f9fb", color: "#167d7f"}}>
        <Toolbar>
          <img src={logo} alt="logo" width={85} />
          <Typography variant="h6" color="DodgerBlue" noWrap>
            &nbsp;Scherstellen Technologie
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            color: "#167d7f",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              sx={{
                color: "#167d7f"
              }}
              //color="text.primary"
              gutterBottom              
            >
              Unsere Technologie - Deine Marke
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Our obsession is making brands stand out, and we have the expertise to do just that. Trust us to elevate your brand and make it truly memorable.
            </Typography>
            <Stack
              sx={{ pt: 4, py: 2}}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained" onClick={() => window.location.replace("/#contact")}>Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack>
            <Grid container spacing={2}>
              {dataSlices[0].map((card) => (
                <Grid item key={card.id} xs={12} sm={6} md={6} lg={4}>
                  <AlbumCard pic={card.pic} heading={card.heading} content={card.content}/>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <CraftsSlice id="middle-card"  />
        < NewWIP />
        <PrivacySlice />       
        <CustomCards id="custom-card"/>
        <FullWidthBanner />
        <SignUpSlice />
        <Container align="center" color="text.secondary">
            <Ratings />
        </Container>
      </main>
      <Container align="center" color="text.secondary" id="contact">
       {/* Old removed content */}
      </Container>
      <StickyFooter id="contact"  />
    </ThemeProvider>
  );
}
