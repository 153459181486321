export default function slicesContent() {
  return [
    [
      {
        id: 1,
        pic: "target",
        heading: "Set A Target",
        content:
          "Helping clients set up business targets is a collaborative process that requires a deep understanding of the client's business and goals. By following these steps and maintaining open communication, you can assist them in creating a roadmap for success and growth in their business.",
      },
      {
        id: 2,
        pic: "scale",
        heading: "Design A Solution",
        content:
          "Our tailored framework meets your specific client's needs and the nature of the project, and you'll have a solid foundation for designing and implementing a successful solution. We design a solution for clients that can vary greatly depending on the client's specific needs and objectives.",
      },
      {
        id: 3,
        pic: "track",
        heading: "Track The Progress",
        content:
          "Effective project progress tracking ensures that projects stay on course, problems are identified and addressed in a timely manner, and stakeholders remain informed. It also allows for data-driven decision-making and helps avoid project delays and overruns.",
      },
    ],
    [
        {
          id: 1,
          pic: "cloudicon",
          heading: "Clarify Long-Term Vision",
          content: "Discuss the client's long-term vision for their business. What do they want to achieve in the coming years? It might involve revenue growth, market expansion, or becoming an industry leader."
        },
        {
          id: 2,
          pic: "techlifeicon",
          heading: "SMART Goals",
          content: "Encourage clients to set Specific, Measurable, Achievable, Relevant, and Time-bound (SMART) goals. These goals are clear, quantifiable, realistic, and come with a set timeframe."
        },
        {
          id: 3,
          pic: "pyramidicon",
          heading: "Financial Targets",
          content: "Discuss financial targets, such as revenue, profit margin, and cash flow. These are often the most critical and measurable aspects of business success. Clients should establish clear financial milestones."
        },
        {
          id: 4,
          pic: "plasmidicon",
          heading: "Adaptability",
          content: "Emphasize the importance of adaptability. Business conditions can change, and clients may need to revise their targets or strategies as circumstances evolve."
        }
        ,{
          id: 5,
          pic: "systemicon",
          heading: "Solution Options",
          content: "Present a range of solution options to the client, including the pros and cons of each. These options should align with the client's objectives and budget constraints."
        },
        {
          id: 6,
          pic: "deviceicon",
          heading: "Customized Solution",
          content: "Per client's preferences and the data, design a customized solution and involve product development, process optimization, technology implementation, or a combination of strategies."
        },
        {
          id: 7,
          pic: "infoicon",
          heading: "Risk Assessment",
          content: "Identify potential risks and challenges associated with the solution and develop a risk mitigation plan. This plan should outline how to address and minimize these risks if they occur."
        },
        {
          id: 8,
          pic: "nasicon",
          heading: "Ongoing Support",
          content: "Offer ongoing support and maintenance services as agreed upon in the initial contract. Also conduct reviews with the client to ensure their satisfaction and address any remaining concerns."
        }
    ],
  ];
}
